
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import { ref } from "vue";

@Component({
  components: { DefaultForm },
})
export default class PasswordChangeForm extends mixins(FormMixin) {
  protected form: any = ref({
    password: null,
    new_password: null,
    new_password_confirmation: null,
  });

  @Prop({
    required: false,
    default: () => {
      return {};
    },
  })
  protected validationErrors!: any;

  public getFormValues(): any {
    return this.form;
  }

  init() {
    //
  }
}
