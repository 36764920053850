
import { Component, Prop, Vue } from "vue-property-decorator";
import Navbar from "@/components/navbar/Navbar.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import "@/assets/sass/master.scss";

@Component({
  components: { Breadcrumbs, Navbar },
})
export default class DefaultLayout extends Vue {
  protected name = "DefaultLayout";

  @Prop({ required: false, default: null })
  public resourceName!: string | null;

  @Prop({ required: false, default: false })
  public showCreateButton!: boolean;

  @Prop({ required: false, default: false })
  public showUpdateButton!: boolean;

  @Prop({ required: false, default: false })
  public showDeleteButton!: boolean;

  @Prop({ required: false, default: null })
  public updateRouteName!: string | null;

  @Prop({ required: false, default: null })
  public deleteUrl!: string | null;

  @Prop({ required: false, default: null })
  public redirectRouteNameOnDeleteSuccess!: string | null;

  @Prop({ required: false, default: false })
  public noDeleteConfirm!: boolean;

  @Prop({ required: false, default: 3 })
  protected defaultDelaySeconds!: number;

  protected onRemove(
    deleteUrl: string,
    redirectRouteNameOnDeleteSuccess: string | null,
    resourceName: string,
    force = false
  ): void {
    if (!force) {
      this.$dialogsService
        .deleteConfirmDialog(resourceName)
        .then((resourceName: string) => {
          //$emit('delete', $route.params.id);
          console.debug(
            "DebugView.delete : ",
            deleteUrl,
            redirectRouteNameOnDeleteSuccess,
            resourceName
          );
          this.onRemove(
            deleteUrl,
            redirectRouteNameOnDeleteSuccess,
            resourceName,
            true
          );
        });

      return;
    }

    this.$apiService
      .delete(deleteUrl)
      .then((response: any) => {
        this.$emit("remove-success", response, resourceName);

        this.$messageService.success(String(this.$t("message.delete_success")));

        if (this.redirectRouteNameOnDeleteSuccess) {
          setTimeout(() => {
            this.$router.replace({
              name: String(redirectRouteNameOnDeleteSuccess),
            });
          }, this.defaultDelaySeconds * 1000);
        }
      })
      .catch((error: any) => {
        console.error("DefaultLayout delete error", error);
      });
  }
}
