
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { IoTStoreMixin } from "@/mixins/Stores/IotStoreMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import { IIotUserVendorConfig } from "@/interfaces/models/IIotUserVendorConfig";

@Component({
  components: { DefaultForm },
})
export default class IotVendorForm extends mixins(IoTStoreMixin) {
  @Prop({ required: true })
  protected iotVendorConfig!: any;

  @Prop({ required: false, default: null })
  protected validationErrors!: any;

  @Prop({ required: false, default: null })
  protected isLoginTestSuccess!: boolean | null;

  @Prop({ required: false, default: false })
  protected loginTestInProgress!: boolean;

  @Watch("iotVendorConfig", { deep: true, immediate: true })
  protected onIotVendorConfigChanged(iotVendorConfig: any) {
    console.log("onIotVendorConfigChanged", iotVendorConfig);
    if (iotVendorConfig?.id) {
      this.loadUserIotVendorConfigAction(iotVendorConfig.id).catch(
        (err: any) => {
          console.log(err);
        }
      );
    }
  }

  @Watch("iotUserVendorConfig", { deep: true, immediate: true })
  protected onIoTUserVendorConfigChanged(
    iotUserVendorConfig: IIotUserVendorConfig
  ) {
    console.log("onUserIoTVendorConfigChanged", iotUserVendorConfig);
    if (iotUserVendorConfig) {
      this.form.credential_name = iotUserVendorConfig.credential_name;
      //this.form.credential_password = iotUserVendorConfig.credential_password;
      this.isPasswordSet = iotUserVendorConfig.is_password_set ?? false;
    }
  }

  protected form: any = {
    credential_name: null,
    credential_password: null,
  };

  protected isPasswordSet = false;

  protected get testloginButtonLabel() {
    if (this.isLoginTestSuccess === false) {
      return this.$t(
        "components.forms.iot_user_vendor_form.buttons.check_login_unsuccessful"
      );
    } else if (this.isLoginTestSuccess) {
      return this.$t(
        "components.forms.iot_user_vendor_form.buttons.check_login_successful"
      );
    } else {
      return this.$t(
        "components.forms.iot_user_vendor_form.buttons.check_login"
      );
    }
  }

  protected get testLoginButtonType(): string {
    if (this.isLoginTestSuccess === false) {
      return "is-danger is-light";
    } else if (this.isLoginTestSuccess) {
      return "is-success is-light";
    } else {
      return "";
    }
  }

  protected get iotUserVendorConfig() {
    if (this.iotVendorConfig?.id) {
      return this.getUserIotVendorConfig(this.iotVendorConfig.id);
    }
    return null;
  }

  protected getFormValues(): any {
    return {
      ...this.form,
      is_password_set: this.isPasswordSet,
    };
  }
}
