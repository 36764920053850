
import { Component } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { mixins } from "vue-class-component";
import { UserStoreMixin } from "@/mixins/Stores/UserStoreMixin";
import { UserProfile } from "@/config/Modules";
import { DEFAULT_DATE_TIME_FORMAT } from "@/config/Formats";
import DefaultProfileForm from "@/components/modules/user/profile/DefaultProfileForm.vue";
import PasswordChangeForm from "@/components/modules/user/profile/PasswordChangeForm.vue";
import { getValidationErrors } from "@/store/crud/functions/getters";
import ProfileMenu from "@/components/modules/user/profile/ProfileMenu.vue";
import ProfileIoTForm from "@/components/modules/user/profile/ProfileIoTForm.vue";

@Component({
  methods: {
    getValidationErrors,
    DEFAULT_DATE_TIME_FORMAT() {
      return DEFAULT_DATE_TIME_FORMAT;
    },
  },
  components: {
    ProfileIoTForm,
    ProfileMenu,
    PasswordChangeForm,
    DefaultProfileForm,
    DefaultLayout,
  },
})
export default class Profile extends mixins(UserStoreMixin) {
  protected activeTab = "tab-common";
  protected onSubmitDefaultProfileForm(data: any): void {
    console.log("Profile.onSubmitDefaultProfileForm", data);
    this.updateUserItemAction({
      resource: UserProfile.resource,
      data: data,
      descriptionField: "name",
    });
  }

  protected onSubmitPasswordChangeForm(data: any): void {
    this.updateUserItemAction({
      resource: [UserProfile.resource, "change-password"].join("/"),
      data: { ...data, name: this.getUserItem?.name ?? "-" },
      descriptionField: "name",
    });
  }

  protected onTabChange(tab: string): void {
    console.log("Profile.onTabChange", tab);
    this.$router.push({ query: { tab: tab } });
  }

  created() {
    if (this.$route.query.tab) {
      this.activeTab = String(this.$route.query.tab);
    }
    this.findOneUserItemAction({
      resource: UserProfile.resource,
    });
  }
}
