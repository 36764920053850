import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const IoTModule = namespace("iot");

@Component({})
export class IoTStoreMixin extends Vue {
  // Actions
  @IoTModule.Action("loadIotVendorConfigs")
  protected loadIotVendorConfigsAction: any;

  @IoTModule.Action("loadUserIotVendorConfig")
  protected loadUserIotVendorConfigAction: any;

  @IoTModule.Action("saveUserIotVendorConfig")
  protected saveUserIotVendorConfigAction: any;

  // Getters
  @IoTModule.Getter("getIotVendorConfigs")
  protected getIotVendorConfigs!: any;

  @IoTModule.Getter("getUserIotVendorConfig")
  protected getUserIotVendorConfig!: any;

  @IoTModule.Getter("getUserIotVendorConfigs")
  protected getUserIotVendorConfigs!: any;

  // Mutations
  @IoTModule.Mutation("SET_IOT_VENDOR_CONFIGS")
  protected setIotVendorConfigs: any;

  @IoTModule.Mutation("SET_USER_IOT_VENDOR_CONFIG")
  protected setUserIotVendorConfig: any;
}
