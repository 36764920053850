var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mx-3"},[_c('Navbar'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('Breadcrumbs',{staticStyle:{"margin-top":"-35px"}})],1),_c('div',{staticClass:"column mt-3"},[_c('div',{staticClass:"buttons is-right"},[(_vm.showDeleteButton && _vm.deleteUrl && _vm.$route?.params?.id)?_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.onRemove(
              _vm.deleteUrl ?? '',
              _vm.redirectRouteNameOnDeleteSuccess,
              _vm.resourceName ?? '',
              _vm.noDeleteConfirm
            )}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
            _vm.showUpdateButton &&
            _vm.$route.name !== _vm.updateRouteName &&
            _vm.$route.params.id
          )?_c('router-link',{attrs:{"to":{ name: _vm.updateRouteName },"custom":""}},[_c('b-button',{attrs:{"type":"is-info"}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-edit"})])])],1):_vm._e(),(
            _vm.showCreateButton &&
            _vm.$route?.name &&
            !_vm.$route.name.includes('Create')
          )?_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.$emit('create')}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-plus"})])]):_vm._e()],1)])]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }