import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getDashboardMessages } from "@/store/app/getters";
import { IDashboardMessage } from "@/store/app/state";

const AppModule = namespace("app");

@Component({})
export class AppStoreMixin extends Vue {
  @AppModule.Action("loadDashboardMessages")
  protected loadDashboardMessagesAction: any;

  @AppModule.Getter("getDashboardMessages")
  protected getDashboardMessages!: Array<IDashboardMessage>;

  @AppModule.Getter("getConfirmedDashboardMessages")
  protected getConfirmedDashboardMessages!: Array<IDashboardMessage>;

  @AppModule.Mutation("ADD_DASHBOARD_MESSAGES")
  protected addDashboardMessages: any;

  @AppModule.Mutation("SET_DASHBOARD_MESSAGES")
  protected setDashboardMessages: any;

  /*
    /**
     * Getters
     

  
    /**
     * Mutations
     
    @AppModule.Mutation("SET_CURRENT_CUSTOMER")
    protected setCurrentCustomer: any;
    */
}
