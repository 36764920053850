
import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { IoTStoreMixin } from "@/mixins/Stores/IotStoreMixin";
import IotVendorForm from "@/components/modules/user/profile/ProfileIotForm/IotVendorForm.vue";

@Component({
  components: { IotVendorForm },
})
export default class ProfileIoTForm extends mixins(IoTStoreMixin) {
  protected selectedIotVendorConfig: any = null;

  // Null => test not executed
  // false => unsuccessful
  // true => successful
  protected isLoginTestSuccess: boolean | null = null;

  protected loginTestInProgress = false;

  @Watch("selectedIotVendorConfig", { deep: true })
  protected onSelectedIotVendorConfigChanged(selectedIotVendorConfig: any) {
    console.log("onSelectedIotVendorConfigChanged", selectedIotVendorConfig);

    this.isLoginTestSuccess = null;
    this.loginTestInProgress = false;
  }

  protected onTestLogin(e: any) {
    console.log("onTestLogin", e);
    this.loginTestInProgress = true;

    this.$apiService
      .put("/iot/test-login", {
        vendor_config: this.selectedIotVendorConfig,
        user_vendor_config: e,
      })
      .then((response: any) => {
        this.isLoginTestSuccess = !!response.data.success;
      })
      .finally(() => {
        this.loginTestInProgress = false;
      });
  }

  protected onSubmitIoTVendorForm(
    data: any,
    selectedIotVendorConfig: any
  ): void {
    this.saveUserIotVendorConfigAction({ data: data });
  }

  created() {
    console.log("ProfileIoTForm created", this.$authService.currentUser);
    this.loadIotVendorConfigsAction();
  }
}
