
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import { IUser } from "@/interfaces/models/IUser";
import { ref } from "vue";

@Component({
  components: { DefaultForm },
})
export default class DefaultProfileForm extends mixins(FormMixin) {
  protected form: any = ref({
    name: null,
  });

  @Prop({ required: false, default: null })
  protected user!: IUser | null;

  @Prop({
    required: false,
    default: () => {
      return {};
    },
  })
  protected validationErrors!: any;

  @Watch("user", { immediate: true, deep: true })
  onUserChanged(user: IUser | null) {
    if (user) {
      this.setFormValues(user);
    }
  }

  public getFormValues(): any {
    return this.form;
  }

  public setFormValues(user: IUser) {
    this.form["name"] = user?.name;
  }

  init() {
    //
  }
}
