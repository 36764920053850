var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DefaultForm',{on:{"submit":function($event){_vm.$emit('submit', _vm.getFormValues())}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{attrs:{"loading":_vm.loginTestInProgress,"type":_vm.testLoginButtonType,"expanded":true},on:{"click":function($event){_vm.$emit('test-login', _vm.getFormValues())}}},[_vm._v(_vm._s(_vm.testloginButtonLabel)+" ")])]},proxy:true},{key:"default",fn:function(formSettings){return [_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
          _vm.$t('components.forms.iot_user_vendor_form.labels.credential_name')
        ),"type":_vm.validationErrors && _vm.validationErrors.credential_name
          ? 'is-danger'
          : '',"message":_vm.validationErrors && _vm.validationErrors.credential_name
          ? _vm.validationErrors.credential_name
          : ''}},[_c('b-input',{attrs:{"type":"text","icon":"","placeholder":String(
            _vm.$t(
              'components.forms.iot_user_vendor_form.placeholders.credential_name'
            )
          ),"required":""},model:{value:(_vm.form.credential_name),callback:function ($$v) {_vm.$set(_vm.form, "credential_name", $$v)},expression:"form.credential_name"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
          _vm.$t(
            'components.forms.iot_user_vendor_form.labels.credential_password'
          )
        ),"type":_vm.validationErrors && _vm.validationErrors.credential_password
          ? 'is-danger'
          : '',"message":_vm.validationErrors && _vm.validationErrors.credential_password
          ? _vm.validationErrors.credential_password
          : ''}},[_c('b-input',{attrs:{"type":"text","icon":"","placeholder":_vm.isPasswordSet
            ? String(
                _vm.$t(
                  'components.forms.iot_user_vendor_form.placeholders.credential_password_set'
                )
              )
            : String(
                _vm.$t(
                  'components.forms.iot_user_vendor_form.placeholders.credential_password_not_set'
                )
              )},model:{value:(_vm.form.credential_password),callback:function ($$v) {_vm.$set(_vm.form, "credential_password", $$v)},expression:"form.credential_password"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }