var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DefaultForm',{attrs:{"is-saving":false,"is-horizontal":false},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('components.forms.password_change_form.labels.password')),"type":_vm.validationErrors && _vm.validationErrors.password ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.password
            ? _vm.validationErrors.password
            : ''}},[_c('b-input',{attrs:{"type":"password","icon":"","password-reveal":"","placeholder":String(
              _vm.$t(
                'components.forms.password_change_form.placeholders.password'
              )
            ),"required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
            _vm.$t('components.forms.password_change_form.labels.new_password')
          ),"type":_vm.validationErrors && _vm.validationErrors.new_password ? 'is-danger' : '',"message":_vm.validationErrors && _vm.validationErrors.new_password
            ? _vm.validationErrors.new_password
            : ''}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","icon":"","placeholder":String(
              _vm.$t(
                'components.forms.password_change_form.placeholders.new_password'
              )
            ),"required":""},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(
            _vm.$t(
              'components.forms.password_change_form.labels.new_password_confirmation'
            )
          ),"type":_vm.validationErrors && _vm.validationErrors.new_password_confirmation
            ? 'is-danger'
            : '',"message":_vm.validationErrors && _vm.validationErrors.new_password_confirmation
            ? _vm.validationErrors.new_password_confirmation
            : ''}},[_c('b-input',{attrs:{"type":"password","password-reveal":"","icon":"","placeholder":String(
              _vm.$t(
                'components.forms.password_change_form.placeholders.new_password_confirmation'
              )
            ),"required":""},model:{value:(_vm.form.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "new_password_confirmation", $$v)},expression:"form.new_password_confirmation"}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }